import React, { useContext, useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./AdvanceDashboard.css";
import Header from "../../Header/Header";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import "react-circular-progressbar/dist/styles.css";
import "swiper/swiper-bundle.css";
import CanvasJSReact from "@canvasjs/react-charts";
import { Context } from "../../../utils/context";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Accordion from "react-bootstrap/Accordion";
import {
  faCartShopping,
  faBoxOpen,
  faUsers,
  faStore,
} from "@fortawesome/free-solid-svg-icons";
import { Chart } from "react-google-charts";
import DataTable from "datatables.net";
import Button from "react-bootstrap/Button";
import { Link, useLocation } from "react-router-dom";
import { Table } from "react-bootstrap";
import AllProductColumnChart from "./AllProduct-PieChart/Product_pie_chart";
import AllSellerColumnChart from "./AllSellerWiseChart/Product_pie_chart";
import DemoChart from "./DemoChart/demochart";
import DonutDemo from "./DonutChart/donutdemochart";
import DonutDemoPart2 from "./DonutChartpart2/donutdemochart";

library.add(fas);

const AdvanceDashboard = () => {
  const { getData, usertype } = useContext(Context);
  const [result, setResult] = useState({});
  const getDataAll = async () => {
    const result = await getData("/dashboard");
    setResult(result.data);
  };

  useEffect(() => {
    // getDataAll();
  }, []);

  // search functionality start

  const [filter, setFilter] = useState("");

  const handleFilterChange = (event) => {
    setFilter(event.target.value.toLowerCase());
  };

  const [countOrders, setCountOrders] = useState("");
  const GetEditData = async () => {
    const res = await getData(`/count-all-studio`);
    setCountOrders(res?.data?.count);
  };

  const [countCustomers, setCountCustomers] = useState("");
  const GetCustomersData = async () => {
    const res = await getData(`/count-all-customers`);
    setCountCustomers(res?.data?.count);
  };

  const [countBookings, setCountBookings] = useState("");
  const GetBookingData = async () => {
    const res = await getData(`/count-all-bookings`);
    setCountBookings(res?.data?.count);
  };

  const [countSellers, setCountSellers] = useState("");
  const GetSellersData = async () => {
    const res = await getData(`/count-all-sellers`);
    setCountSellers(res?.data?.count);
  };
  useEffect(() => {
    GetEditData();
    GetCustomersData();
    GetSellersData();
    GetBookingData();
  }, []);

  const cardsData = [
    // {
    //   number: countOrders,
    //   types: "Studio",
    //   icon: "fa-cart-shopping",
    //   className: "#63bff5",
    //   api: "/studio",
    // },

    {
      number: countCustomers,
      types: "Customers",
      icon: "fa-solid fa-users",
      className: "#63bff5",
      api: "/employee/customer-details",
    },

    // {
    //   number: countBookings,
    //   types: "Bookings",
    //   icon: "fa-solid fa-users",
    //   className: "#63bff5",
    //   api: "/booking",
    // },
  ];

  const options = {
    data: [
      {
        color: "#4285f4",
        width: 100,
        dataPoints: [
          { label: "29-March", y: 10 },
          { label: "28-March", y: 15 },
          { label: "20-March", y: 2 },
          { label: "18-March", y: 30 },
          { label: "13-March", y: 18 },
          { label: "27-March", y: 10 },
          { label: "30-March", y: 20 },
        ],
      },
    ],
  };

  // pie chart
  const dataOne = [
    ["Task", "Hours per Day"],
    ["Work", 11],
    ["Eat", 2],
    ["Commute", 2],
    ["Watch TV", 2],
    ["Sleep", 7],
  ];

  const optiontwo = {
    title: "Product Category Count",
    is3D: true,
  };

  // close button
  const [showBox, setShowBox] = useState(true);

  const handleClose = () => {
    setShowBox(false);
  };

  useEffect(() => {
    const table = new DataTable("#example");
    return () => {
      table.destroy();
    };
  }, []);

  useEffect(() => {
    const table = new DataTable("#exampleContent");
    return () => {
      table.destroy();
    };
  }, []);

  const datanew = [
    {
      Name: "Tiger Nixon",
      Position: "System Architect",
      Office: "Edinburgh",
      Age: 61,
      StartDate: "2011-04-25",
      Salary: "$320,800",
    },
    {
      Name: "Garrett Winters",
      Position: "Accountant",
      Office: "Tokyo",
      Age: 63,
      StartDate: "2011-07-25",
      Salary: "$170,750",
    },
  ];

  const datacontent = [
    {
      Name: "Tiger Nixon",
      Position: "System Architect",
      Office: "Edinburgh",
      Age: 61,
      StartDate: "2011-04-25",
      Salary: "$320,800",
    },
    {
      Name: "Garrett Winters",
      Position: "Accountant",
      Office: "Tokyo",
      Age: 63,
      StartDate: "2011-07-25",
      Salary: "$170,750",
    },
  ];

  const colors = ["#63bff5", "#f39c12", "#dd4b39", "#00a65a"];

  const [productGraph, setProductGraph] = useState("");
  const [sellerGraph, setSellerGraph] = useState("");

  console.log(sellerGraph, "sellerGraph");

  const [productForGraph, setProductForGraph] = useState([]);
  const [productGraphCount, setProductGraphCount] = useState([]);

  const [sellerForGraph, setSellerForGraph] = useState([]);

  const [sellerGraphCount, setSellerGraphCount] = useState([]);

  console.log(sellerGraphCount, "sellerGraphCount");
  console.log(sellerForGraph, "sellerForGraph");

  const GetAllProductGraph = async (days) => {
    try {
      const res = await getData(`/all-seller-booking`);
      setProductGraph(res?.data?.data);
    } catch (error) {
      console.error("Error fetching seller data:", error);
    }
  };

  const GetAllSellerGraph = async (days) => {
    try {
      const res = await getData(`/all-booking-of-seller`);
      setSellerGraph(res?.data?.data);
    } catch (error) {
      console.error("Error fetching seller data:", error);
    }
  };

  useEffect(() => {
    if (productGraph) {
      // Extracting months
      const extractedProduct = productGraph
        .map((value) => value.month)
        .filter((month) => month !== undefined);
      setProductForGraph(extractedProduct);

      // Extracting counts
      const extractedProductCount = productGraph
        .map((value) => value.count)
        .filter((count) => count !== undefined);
      setProductGraphCount(extractedProductCount);
    }
  }, [productGraph]);

  useEffect(() => {
    if (sellerGraph) {
      // Extracting months
      const extractedProduct = sellerGraph
        .map((value) => value.vendorName)
        .filter((month) => month !== undefined);
      setSellerForGraph(extractedProduct);

      // Extracting counts
      const extractedProductCount = sellerGraph
        .map((value) => value.count)
        .filter((count) => count !== undefined);
      setSellerGraphCount(extractedProductCount);
    }
  }, [sellerGraph]);

  useEffect(() => {
    GetAllProductGraph();
    GetAllSellerGraph();
  }, []);

  return (
    <div className="main-advancedashboard">
      <section className="AdvanceDashboard">
        <div className="AdvanceDashboardTabs">
          <Header title={"Dashboard"} link={"/masters/social_links"} />
          <div className=""></div>
        </div>
      </section>
      <div className="Main-Section-Advanced-Dashboard">
        <h1 className="title">Home</h1>
        <Row>
          {cardsData.map((item, index) =>
            usertype === "Admin" ? (
              <Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={12}>
                <Card
                  className="small-box"
                  style={{ backgroundColor: colors[index % colors.length] }}
                >
                  <div className={item.className}>
                    <div className="content">
                      <div class="inner">
                        <h3>{item.number}</h3>
                        <p>{item.types}</p>
                      </div>
                      <div class="icon">
                        <FontAwesomeIcon icon={item.icon} />
                      </div>
                    </div>

                    <Link to={item?.api}>
                      <p className="mb-0 small-box-footer">
                        More info{" "}
                        <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" />
                      </p>
                    </Link>
                  </div>
                </Card>
              </Col>
            ) : (
              index < 3 && (
                <Col xxl={3} xl={3} lg={3} md={6} sm={12} xs={12}>
                  <Card
                    className="small-box"
                    style={{ backgroundColor: colors[index % colors.length] }}
                  >
                    <div className={item.className}>
                      <div className="content">
                        <div class="inner">
                          <h3>{item.number}</h3>
                          <p>{item.types}</p>
                        </div>
                        <div class="icon">
                          <FontAwesomeIcon icon={item.icon} />
                        </div>
                      </div>

                      <Link to={item?.api}>
                        <p className="mb-0 small-box-footer">
                          More info{" "}
                          <FontAwesomeIcon icon="fa-solid fa-circle-arrow-right" />
                        </p>
                      </Link>
                    </div>
                  </Card>
                </Col>
              )
            )
          )}
        </Row>

        {/* <div className=" col-12">
          <div className="chart-heading">
            <h1 className="title-classss mt-3">All Bookings</h1>
          </div>
         
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12  mb-5">
              <DemoChart
                productData={productForGraph}
                productCount={productGraphCount}
              />
            </div>

            <div className="col-xl-6 col-lg-12 col-md-12 mb-5">
              <DonutDemo
                productData={productForGraph}
                productCount={productGraphCount}
              />
            </div>

            <div className="col-xl-6 col-lg-12 col-md-12 mb-5">
              <DonutDemoPart2
                productData={productForGraph}
                productCount={productGraphCount}
              />
            </div>
          </div>

         
        </div> */}

        {/* <div className=" col-12">
          <div className="chart-heading">
            <h1 className="title-classss mt-3">All Bookings</h1>
          </div>
          {productForGraph?.length > 0 && productGraphCount?.length > 0 && (
            <AllProductColumnChart
              productData={productForGraph}
              productCount={productGraphCount}
            />
          )}
        </div> */}
        {/* {usertype == "Admin" && (
          <div className=" col-12">
            <div className="chart-heading">
              <h1 className="title-classss mt-3"> Vendor Bookings</h1>
            </div>
            {sellerForGraph?.length > 0 && sellerGraphCount?.length > 0 && (
              <AllSellerColumnChart
                productData={sellerForGraph}
                productCount={sellerGraphCount}
              />
            )}
          </div>
        )} */}
      </div>
    </div>
  );
};

export default AdvanceDashboard;
