import React, { useState, handlelick, active, useContext } from "react";
import "./Sidebar.css";
import { Link, useLocation } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Context } from "../../utils/context";
import { faAffiliatetheme } from "@fortawesome/free-brands-svg-icons";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";

library.add(fas);

const Sidebar = () => {
  const { isSidebarOpen, setSidebarOpen, isAllow } = useContext(Context);
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(true);
  const [showItemNames, setShowItemNames] = useState(true);
  const [headerText, setHeaderText] = useState(location.pathname);

  useEffect(() => {
    setHeaderText(location.pathname);
  });

  const [active, setActive] = useState("0");
  const handleClick = (eventKey) => {
    if (active === eventKey) {
      setActive(null);
    } else {
      setActive(eventKey);
      // setSidebarOpen(true);
    }
  };

  const isActive = (path) => {
    return location.pathname.includes(path) ? "active block" : "none";
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <section className="sidebar">
        <div className="desktop-view-sidebar">
          <div className="top_section">
            <img
              className="logo "
              src={process.env.PUBLIC_URL + "/FNS_logo.png"}
            // src={process.env.PUBLIC_URL + "/netpurtilogo.png"}
            // src={process.env.PUBLIC_URL + "/Mansa_Nutri_Logo.png"}
            />
            {/* <h3>Flick And Shoot</h3>/ */}
            {/* <h1>Net Purti</h1> */}
          </div>

          <ul className="list-unstyled ps-0">
            {/* ***************************************************Dashboard*************************************************************** */}

            <Accordion
              // defaultActiveKey="0"
              className=""
              data-bs-toggle="collapse"
              data-bs-target="#home-collapse"
              aria-expanded="false"
            >
              <Accordion.Item
                eventKey="0"
                className=""
                onClick={() => handleClick("0")}
              >
                <Accordion.Header>
                  <div
                    className="Accordion_Header_icon-holder
"
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-house"
                      onClick={() => setIsOpen(!isOpen)}
                    />
                  </div>
                  <p
                    className="sidebar_txt"
                    style={{ display: isSidebarOpen ? "block" : "none" }}
                    onClick={() => setSidebarOpen(true)}
                  >
                    Dashboard
                  </p>

                  {/* </button> */}
                </Accordion.Header>
                <Accordion.Body className="p-0">
                  <ul
                    className={`accordion_list list-unstyled fw-normal pb-1 small ${isSidebarOpen ? "arrowshow" : "arrowHide"
                      }`}
                    style={{
                      display: isSidebarOpen ? "block" : "none",
                    }}
                  >
                    <Link
                      to="/advanceDashboard"
                      className="text-decoration-none"
                    >
                      <li className={isActive("/advanceDashboard")}>
                        <p key={2} className="insideDropdown_list" id={"2"}>
                          Advance Dashboard
                        </p>
                      </li>
                    </Link>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
             
              {/* *********************************************************Dashboard Ends*************************************************************** */}
              {/* {isAllow?.includes(1) ||
                isAllow?.includes(2) ||
                isAllow?.includes(3) ||
                isAllow?.includes(4) ||
                isAllow?.includes(5) ||
                isAllow?.includes(6) ||
                isAllow?.includes(7) ||
                isAllow?.includes(8) ? (
                <Accordion.Item
                  eventKey="16"
                  className=""
                  onClick={() => handleClick("16")}
                >
                  <Accordion.Header>
                    <div
                      className="Accordion_Header_icon-holder
"
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-people-roof"
                      // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Role Management
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                    // style={{
                    //   display: isSidebarOpen ? "block" : "none",
                    // }}
                    >
                      {isAllow?.includes(1) ? (
                        <Link
                          to="/employee/role"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/employee/role")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Roles
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                <></>
              )} */}

              

              
              {/* ************************************************************   Content Start ************************************************************* */}
              
                <Accordion.Item
                  eventKey="20"
                  className=""
                  onClick={() => handleClick("20")}
                >
                  <Accordion.Header>
                    <div
                      className="Accordion_Header_icon-holder
"
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-gear"
                      // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Settings
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                    // style={{
                    //   display: isSidebarOpen ? "block" : "none",
                    // }}
                    >
                    
                        <Link
                          to="/settings/customization/faqs"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                  "/settings/customization/faqs"
                                )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              FAQ
                            </p>
                          </li>
                        </Link>

                        <Link
                          to="/master/currency"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                  "/master/currency"
                                )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Currency
                            </p>
                          </li>
                        </Link>

                        <Link
                          to="/master/competitions"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                  "/master/competitions"
                                )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Competitions
                            </p>
                          </li>
                        </Link>
                                        
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item
                  eventKey="2"
                  className=""
                  onClick={() => handleClick("2")}
                >
                  <Accordion.Header>
                    <div
                      className="Accordion_Header_icon-holder
"
                    >
                      <FontAwesomeIcon icon="fa fa-bullseye" />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Categories
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul className="accordion_list list-unstyled fw-normal pb-1 small">
                      <Link
                        to="/settings/category"
                        className=" text-decoration-none  "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes("/settings/category")
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Category
                          </p>
                        </li>
                      </Link>

                      <Link
                        to="/settings/sub-category"
                        className=" text-decoration-none  "
                      >
                        <li
                          className={
                            isOpen
                              ? headerText.includes("/settings/sub-category")
                                ? "active block"
                                : "none"
                              : "none"
                          }
                        >
                          <p
                            className="insideDropdown_list"
                            style={{ display: isOpen ? "block" : "none" }}
                          >
                            Sub Category
                          </p>
                        </li>
                      </Link>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
             
              {/* ************************************************************  Settings End ************************************************************* */}
              {/* ************************************************************  Categories ************************************************************* */}
             
              {/* ************************************************************  Categories End ************************************************************* */}
              {/* ************************************************************  Home Settings  ************************************************************* */}
             
                <Accordion.Item
                  eventKey="9"
                  className=""
                  onClick={() => handleClick("9")}
                >
                  <Accordion.Header>
                    <div
                      className="Accordion_Header_icon-holder
"
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-house-laptop"

                      // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Home Settings
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                    // style={{
                    //   display: isSidebarOpen ? "block" : "none",
                    // }}
                    >
                      {/* {isAllow?.includes(37) ? ( */}
                        <Link
                          to="/settings/customization/home-banner"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                  "/settings/customization/home-banner"
                                )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Home Banner
                            </p>
                          </li>
                        </Link>
                      {/* ) : (
                        <></>
                      )} */}

                   
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item
                  eventKey="30"
                  className=""
                  onClick={() => handleClick("30")}
                >
                  <Accordion.Header>
                    <div
                      className="Accordion_Header_icon-holder
"
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-house-laptop"

                      // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                     Virtual
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                    // style={{
                    //   display: isSidebarOpen ? "block" : "none",
                    // }}
                    >
                      {/* {isAllow?.includes(37) ? ( */}
                        <Link
                          to="/masters/virtual"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                  "/masters/virtual"
                                )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                            Virtual
                            </p>
                          </li>
                        </Link>
                      {/* ) : (
                        <></>
                      )} */}

                   
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
             
              {/* ************************************************************   Home Settings End ************************************************************* */}
              {/* ************************************************************  Customers ************************************************************* */}
             
                <Accordion.Item
                  eventKey="18"
                  className=""
                  onClick={() => handleClick("18")}
                >
                  <Accordion.Header>
                    <div
                      className="Accordion_Header_icon-holder
"
                    >
                      <FontAwesomeIcon
                        icon="fa fa-users"
                      // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Customers
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul className="accordion_list list-unstyled fw-normal pb-1 small">
                    
                        <Link
                          to="/employee/contact-us"
                          className=" text-decoration-none  "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/employee/contact-us")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Contact Us
                            </p>
                          </li>
                        </Link>
                      
                        <Link
                          to="/employee/inquiry"
                          className=" text-decoration-none  "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/employee/inquiry")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Inquiry
                            </p>
                          </li>
                        </Link>
                    
                      {/* {isAllow?.includes(5) ? ( */}
                        <Link
                          to="/employee/customer-details"
                          className="text-decoration-none"
                        >
                          <li
                            className={isActive("/employee/customer-details")}
                          >
                            <p key={2} className="insideDropdown_list" id={"2"}>
                              Customer Details
                            </p>
                          </li>
                        </Link>
                      {/* ) : (
                        <></>
                      )} */}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              
              {/* ************************************************************  Customers End ************************************************************* */}
              {/* ************************************************************  Customization ************************************************************* */}
             
                {/* <Accordion.Item
                  eventKey="5"
                  className=""
                  onClick={() => handleClick("5")}
                >
                  <Accordion.Header>
                    <div
                      className="Accordion_Header_icon-holder
"
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-sliders"
                     
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      App Setup
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul className="accordion_list list-unstyled fw-normal pb-1 small">
                      
                        <Link
                          to="/settings/customization/app-setup"
                          className=" text-decoration-none  "
                        
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                  "/settings/customization/app-setup"
                                )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              App Setup
                            </p>
                          </li>
                        </Link>
                     
                    </ul>
                  </Accordion.Body>
                </Accordion.Item> */}
              
              {/* ************************************************************  Customization End ************************************************************* */}
              {/* ************************************************************  Location ************************************************************* */}
              {/* {isAllow?.includes(9) ||
                isAllow?.includes(10) ||
                isAllow?.includes(11) ||
                isAllow?.includes(12) ||
                isAllow?.includes(13) ||
                isAllow?.includes(14) ||
                isAllow?.includes(15) ||
                isAllow?.includes(16) ||
                isAllow?.includes(17) ||
                isAllow?.includes(18) ||
                isAllow?.includes(19) ||
                isAllow?.includes(20) ||
                isAllow?.includes(21) ||
                isAllow?.includes(22) ||
                isAllow?.includes(23) ||
                isAllow?.includes(24) ? ( */}
                <Accordion.Item
                  eventKey="8"
                  className=""
                  onClick={() => handleClick("8")}
                >
                  <Accordion.Header>
                    <div
                      className="Accordion_Header_icon-holder
"
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-map-location-dot"

                      // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Location
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                    // style={{
                    //   display: isSidebarOpen ? "block" : "none",
                    // }}
                    >
                      {/* {isAllow?.includes(9) ? ( */}
                        <Link
                          to="/settings/location/country"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                  "/settings/location/country"
                                )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Country
                            </p>
                          </li>
                        </Link>
                      {/* ) : (
                        <></>
                      )} */}

                      {/* {isAllow?.includes(13) ? ( */}
                        <Link
                          to="/settings/location/state"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                  "/settings/location/state"
                                )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              State
                            </p>
                          </li>
                        </Link>
                      {/* ) : (
                        <></>
                      )} */}

                      {/* {isAllow?.includes(17) ? ( */}
                        <Link
                          to="/settings/location/city"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/settings/location/city")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              City
                            </p>
                          </li>
                        </Link>
                      {/* ) : (
                        <></>
                      )} */}
                      {/* {isAllow?.includes(21) ? ( */}
                        <Link
                          to="/settings/location/pincode"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes(
                                  "/settings/location/pincode"
                                )
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Pincode
                            </p>
                          </li>
                         </Link>
                    {/*  ) : (
                        <></>
                      )} */}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              {/* ) : (
                <></>
              )} */}
              {/* ************************************************************  Location End ************************************************************* */}
              {/* ************************************************************  Why  Section  ************************************************************* */}
             
              {/* ************************************************************   Why End ************************************************************* */}
              {/* ************************************************************   About Us Start ************************************************************* */}
             
                <Accordion.Item
                  eventKey="17"
                  className=""
                  onClick={() => handleClick("17")}
                >
                  <Accordion.Header>
                    <div
                      className="Accordion_Header_icon-holder
"
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-gear"
                      // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      About Us
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul className="accordion_list list-unstyled fw-normal pb-1 small">
                      {/* {isAllow?.includes(65) ? ( */}
                        <Link
                          to="/about-us/about-banner"
                          className=" text-decoration-none  "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/about-us/about-banner")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              About Warri Sports
                            </p>
                          </li>
                        </Link>
                      {/* ) : (
                        <></>
                      )} */}

                     

                      {isAllow?.includes(77) ? (
                        <Link
                          to="/about-us/social-media"
                          className=" text-decoration-none  "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/about-us/social-media")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Social Media
                            </p>
                          </li>
                        </Link>
                      ) : (
                        <></>
                      )}

                      
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              
              {/* ************************************************************   About Us End ************************************************************* */}
              {/* ************************************************************   Content Start ************************************************************* */}
              
                <Accordion.Item
                  eventKey="19"
                  className=""
                  onClick={() => handleClick("19")}
                >
                  <Accordion.Header>
                    <div
                      className="Accordion_Header_icon-holder
"
                    >
                      <FontAwesomeIcon
                        icon="fa-solid fa-gear"
                      // onClick={() => setIsOpen(!isOpen)}
                      />
                    </div>
                    <p
                      className="sidebar_txt"
                      style={{ display: isSidebarOpen ? "block" : "none" }}
                    >
                      Policy Content
                    </p>
                  </Accordion.Header>
                  <Accordion.Body
                    className="p-0"
                    style={{
                      display: isOpen ? "block" : "none",
                    }}
                  >
                    <ul
                      className="accordion_list list-unstyled fw-normal pb-1 small"
                    // style={{
                    //   display: isSidebarOpen ? "block" : "none",
                    // }}
                    >
                     
                        <Link
                          to="/content/privacy"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/content/privacy")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Privacy Policy
                            </p>
                          </li>
                        </Link>
                     
                    
                        <Link
                          to="/content/terms"
                          className=" text-decoration-none  "
                        // className="text-decoration-none rounded "
                        >
                          <li
                            className={
                              isOpen
                                ? headerText.includes("/content/terms")
                                  ? "active block"
                                  : "none"
                                : "none"
                            }
                          >
                            <p
                              className="insideDropdown_list"
                              style={{ display: isOpen ? "block" : "none" }}
                            >
                              Terms & Conditions
                            </p>
                          </li>
                        </Link>
                     
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
             

             

             

            
              {/* ************************************************************  Content End ************************************************************* */}
            </Accordion>
          </ul>
        </div>
      </section>
    </>
  );
};

export default Sidebar;
