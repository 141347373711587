import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";

import Login from "./Components/Login/Login";
import PageNotFound from "../src/Components/PageNotFound/PageNotFound";
// Dashboard
import Dashboard from "./Components/DashBoard/DashBoard";
import Sidebar from "./Components/Sidebar/Sidebar";
import Header from "./Components/Header/Header";
import AdvanceDashboard from "./Components/DashBoard/AdvanceDashboard/AdvanceDashboard2";

import { Context } from "./utils/context";
import { IDS } from "./utils/common";

import Role from "./Components/EmployeeManagement/Role/Table";

import CustomerDetails from "./Components/EmployeeManagement/CustomerDetails/Table";

import ContactUs from "./Components/CustomerManagement/ContactUSList/Tables";
import Inquiry from "./Components/CustomerManagement/Inquiry/Table";


import SocialMedia from "./Components/AboutUs/SocialMedia/Tables";

import AboutBanner from "./Components/AboutUs/AboutBanner/Tables";
// import AboutUs from "./Components/AboutUs/AboutUs/Table";


// Settings


// Customization
import WebHeader from "./Components/Masters/WebHeader/Tables";
import AppSetup from "./Components/Masters/AppSetup/Tables";
import HomeBanner from "./Components/Masters/S_Banner/Tables";
import HomeAdBanner from "./Components/Masters/Virtual/Tables";


import FaqCategory from "./Components/Masters/FaqCategory/Tables";
import Faq from "./Components/Masters/FAQs/Tables";
import Category from "./Components/Masters/Category/Tables";
import SubCategory from "./Components/Masters/Sub_Category/Tables";

import Country from "./Components/Masters/Country/Tables";
import State from "./Components/Masters/State/Tables";
import City from "./Components/Masters/City/Tables";
import Pincode from "./Components/Masters/Pincode/Tables";
import Virtual from "./Components/Masters/Virtual/Tables";
import Currency from "./Components/Masters/Currency/Tables";
import Competitions from "./Components/Masters/Competitions/Tables";
//Content
import Privacy from "./Components/Content/Privacy/Tables";
import Terms from "./Components/Content/Terms/Tables";


const App = () => {
  const location = useLocation();
  const { isSidebarOpen, isAllow } = useContext(Context);
  const [headerText, setHeaderText] = useState(location.pathname);

  const isLoginPage = location.pathname === "/";

  console.log(isAllow, "isAllow");

  useEffect(() => {
    setHeaderText(location.pathname);
  });

  const [urls, setUrls] = useState([]);
  useEffect(() => {
    setUrls([
      {
        path: "/",
        element: <Login />,
        status: true,
      },
      {
        path: "/Header",
        element: <Header />,
        status: true,
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
        status: true,
      },
      {
        path: "/advanceDashboard",
        element: <AdvanceDashboard />,
        status: true,
      },
      {
        path: "/settings/category",
        element: <Category />,
        status: true,
      },
      {
        path: "/settings/sub-category",
        element: <SubCategory />,
        status: true,
      },
      {
        path: "/master/currency",
        element: <Currency />,
        status: true,
      },
      {
        path: "/master/competitions",
        element: <Competitions />,
        status: true,
      },
      {
        path: "/employee/role",
        element: <Role />,
        status: true,
        // status: isAllow?.includes(IDS.Role.List),
      },
    
      {
        path: "/employee/customer-details",
        element: <CustomerDetails />,
        status: isAllow?.includes(IDS.User.List),
      },
      {
        path: "/employee/contact-us",
        element: <ContactUs />,
        status: true,
        // status: isAllow?.includes(IDS.ContactUs.List),
      },
      {
        path: "/employee/inquiry",
        element: <Inquiry />,
        status: true,
        // status: isAllow?.includes(IDS.Enquiry.List),
      },
     
      {
        path: "/about-us/social-media",
        element: <SocialMedia />,
        status: true,
        // status: isAllow?.includes(IDS.SocialMedia.List),
      },
      {
        path: "/masters/virtual",
        element: <Virtual />,
        status: true,
        // status: isAllow?.includes(IDS.SocialMedia.List),
      },
      {
        path: "/about-us/about-banner",
        element: <AboutBanner />,
        status: true,
        // status: isAllow?.includes(IDS.AboutUsBanner.List),
      },
      // {
      //   path: "/about-us/about-us-main",
      //   element: <AboutUs />,
      //   status: true,
      //   // status: isAllow?.includes(IDS.HomeAboutUs.List),
      // },
     
      {
        path: "/content/privacy",
        element: <Privacy />,
        status: true,
        // status: isAllow?.includes(IDS.PrivacyPolicy.List),
      },
      {
        path: "/content/terms",
        element: <Terms />,
        status: true,
        // status: isAllow?.includes(IDS.TermsConditions.List),
      },
     
      {
        path: "/settings/customization/web-header",
        element: <WebHeader />,
        status: true,
      },
      {
        path: "/settings/customization/app-setup",
        element: <AppSetup />,
        status: true,
        // status: isAllow?.includes(IDS.AppSetup.List),
      },
      {
        path: "/settings/customization/home-banner",
        element: <HomeBanner />,
        status: true,
        // status: isAllow?.includes(IDS.HomeBanner.List),
      },
      
      {
        path: "/settings/customization/home-ad-banner",
        element: <HomeAdBanner />,
        status: true,
      },
      
      {
        path: "/settings/customization/faq-category",
        element: <FaqCategory />,
        status: true,
      },
      {
        path: "/settings/customization/faqs",
        element: <Faq />,
        status: true,
        // status: isAllow?.includes(IDS.FAQList.List),
      },
      
      {
        path: "/settings/location/country",
        element: <Country />,
        status: true,
        // status: isAllow?.includes(IDS.Country.List),
      },
      {
        path: "/settings/location/state",
        element: <State />,
        status: true,
        // status: isAllow?.includes(IDS.State.List),
      },
      {
        path: "/settings/location/city",
        element: <City />,
        status: true,
        // status: isAllow?.includes(IDS.City.List),
      },
      {
        path: "/settings/location/pincode",
        element: <Pincode />,
        status: true,
        // status: isAllow?.includes(IDS.Pincode.List),
      },
     
    ]);
  }, [isAllow]);

  return (
    <div
      className={`main ${isSidebarOpen ? "sidebar-open" : "sidebar-closed"}`}
    >
      {isLoginPage ? (
        <Login />
      ) : (
        <div className=" d-flex sidebar-x-scroll">
          <div
            className={`${
              isSidebarOpen
                ? "sidebarHeadopen "
                : "scale-in-hor-left sidebarHeadClose"
            }  pe-0`}
          >
            <Sidebar />
          </div>
          <div
            className={`${
              isSidebarOpen
                ? "dashboradopen"
                : "scale-in-hor-right dashboradopen"
            }  ps-0`}
          >
            <div className="allRoutesMain">
              <Routes>
                {urls?.map(
                  (url) =>
                    url.status && (
                      <Route path={url.path} element={url.element} />
                    )
                )}
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </div>
            {/* <Home /> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
