import React, { useContext } from "react";
import { useState, useEffect } from "react";
import "../../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

// *******************toster****************************
import Select from "react-select";
import ModalSave from "../../common/ModelSave";
import { CancelButton, SaveButton } from "../../common/Button";
import { Row, Col, Form, InputGroup, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import JoditEditor from "jodit-react";
library.add(fas);

const AddOffCanvance = (props) => {
  const { postData, getData, Select2Data } = useContext(Context);

  const [countries, setCountries] = useState([]);

  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });

  // const GetAllCategory = async () => {
  //   const response = await getData("/all-faq-category");

  //   if (response?.success) {
  //     setCountries(await Select2Data(response?.data, "faq_category_id"));
  //   }
  // };

  // useEffect(() => {
  //   GetAllCategory();
  // }, []);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    trigger,
    setValue,
  } = useForm();
  const descriptionValue = watch("description");
  const onSubmit = async (data) => {
    try {
      // console.log("faq_category_id :- ", data?.faq_category_id);
      const DataToSend = new FormData();
      // DataToSend.append("faq_category_id", data?.faq_category_id?.value);
      DataToSend.append("name", data?.name);
      DataToSend.append("description", data?.description);
      const response = await postData(`/masters/faq`, DataToSend);
      console.log(response);
      console.log(DataToSend);
      if (response?.success) {
        await setShowModal({ code: response.code, message: response.message });
      } else {
        await setShowModal({ code: response.code, message: response.message });
      }
      setTimeout(() => {
        setShowModal(0);
        props.handleClose();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    register("description", {
      required: "Description is required.",
      // maxLength: {
      //   value: 85,
      //   message: 'Description must be at most 85 characters long.'
      // }
    });
  }, [register]);

  return (
    <>
      <Modal
        {...props}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="edit-modal-holder"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Add FAQ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={() => handleSubmit(onSubmit)}
            role="form"
            // className="stateclass"
          >
            <Row>
              {/* <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="row justify-content-center mb-2">
                    <Form.Label>Category</Form.Label>
                    <Controller
                      name="faq_category_id" // name of the field
                      {...register("faq_category_id", {
                        required: "Select Category",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.faq_category_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={countries}
                        />
                      )}
                    />
                    {errors.faq_category_id && (
                      <span className="text-danger">
                        {errors.faq_category_id.message}
                      </span>
                    )}
                  </Row>
                </div>
              </Col> */}

              <Col md={6}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-center">
                    <Form.Label>FAQ ?</Form.Label>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Type The Question"
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          {...register("name", {
                            required: "Question is required",
                          })}
                        />
                      </InputGroup>
                      {errors.name && (
                        <span className="text-danger">
                          {errors.name.message}
                        </span>
                      )}
                    </Form.Group>
                  </Row>
                </div>
              </Col>

              <Col lg={12}>
                <div className="main-form-section mt-3">
                  <Row className="justify-content-start">
                    {" "}
                    {/* Align to left */}
                    {/* description */}
                    <Col sm={12}>
                      <Form.Label className="text-center">
                        description
                      </Form.Label>
                      <Form.Group>
                        <InputGroup>
                          <Form.Control
                            type="text"
                            name="description"
                            placeholder="Description"
                            className={classNames("", {
                              "is-invalid": errors?.description,
                            })}
                            {...register("description", {
                              required: "Description is required",
                            })}
                          />
                        </InputGroup>
                        {errors.name && (
                          <span className="text-danger">
                            {errors.name.message}
                          </span>
                        )}
                      </Form.Group>
                      {errors.description && (
                        <span className="text-danger">
                          {errors.description.message}
                        </span>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Row className="mt-5 pb-3">
                <div className="d-flex justify-content-center">
                  <Link>
                    <CancelButton
                      name={"cancel"}
                      handleClose={props.handleClose}
                    />
                  </Link>

                  <SaveButton
                    name={"save"}
                    handleSubmit={handleSubmit(onSubmit)}
                  />
                </div>
              </Row>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      <ModalSave
        message={showModal.message}
        showErrorModal={showModal.code ? true : false}
      />
    </>
  );
};

export default AddOffCanvance;
