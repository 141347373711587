export const Per_Page_Dropdown = async (data) => {
  const a = [];
  for (let i = 5; i <= data; i *= 2) {
    a.push(i);
  }
  a.push(data);
  // console.log(a);

  return a;
};

export const ImageValidation = {
  files_type: ["jpg", "png", "jpeg"],
  product: { w: 578, h: 762 },
};

export const RequiredIs = {
  roles: [1],
  service_term: [1, 2, 3, 4],
  service_type: [1, 2, 3],
  product_type: [1, 2, 3, 4],
};

export const Select2Data = async (data, name, other = false) => {
  const result = data.map((data) => ({
    value: data?.id,
    label: data?.name,
    name: name,
  }));

  if (other) {
    result.push({ value: "0", label: "Other", name: name });
  }
  return result;
};

export const getDimension = async (file) => {
  let reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    reader.onload = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = function () {
        resolve({ width: this.width, height: this.height });
      };
    };
    reader.readAsDataURL(file);
  });
};

export function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toISOString().split("T")[0];
}

export const IDS = {
  Role: { List: 1, Add: 2, Edit: 3, Delete: 4 },
  User: { List: 5, Add: 6, Edit: 7, Delete: 8 },
  Country: { List: 9, Add: 10, Edit: 11, Delete: 12 },
  State: { List: 13, Add: 14, Edit: 15, Delete: 16 },
  City: { List: 17, Add: 18, Edit: 19, Delete: 20 },
  Pincode: { List: 21, Add: 22, Edit: 23, Delete: 24 },
  CountryCode: { List: 25, Add: 26, Edit: 27, Delete: 28 },
  Category: { List: 29, Add: 30, Edit: 31, Delete: 32 },
  SubCategory: { List: 33, Add: 34, Edit: 35, Delete: 36 },
  HomeBanner: { List: 37, Add: 38, Edit: 39, Delete: 40 },
  HomeMotivatedBy: { List: 41, Add: 42, Edit: 43, Delete: 44 },
  FAQList: { List: 45, Add: 46, Edit: 47, Delete: 48 },
  AddOn: { List: 49, Add: 50, Edit: 51, Delete: 52 },
  AdditionalSupport: { List: 53, Add: 54, Edit: 55, Delete: 56 },
  BookingCancelReason: { List: 57, Add: 58, Edit: 59, Delete: 60 },
  WhyFNS: { List: 61, Add: 62, Edit: 63, Delete: 64 },
  AboutUsBanner: { List: 65, Add: 66, Edit: 67, Delete: 68 },
  OurVissionMission: { List: 69, Add: 70, Edit: 71, Delete: 72 },
  AppSetup: { List: 73, Add: 74, Edit: 75, Delete: 76 },
  SocialMedia: { List: 77, Add: 78, Edit: 79, Delete: 80 },
  HomeAboutUs: { List: 83, Add: 84, Edit: 85, Delete: 86 },
  OurTeam: { List: 87, Add: 88, Edit: 89, Delete: 90 },
  PrivacyPolicy: { List: 91, Add: 92, Edit: 93, Delete: 94 },
  TermsConditions: { List: 95, Add: 96, Edit: 97, Delete: 98 },
  Studio: { List: 99, Add: 100, Edit: 101, Delete: 102 },
  Special: { List: 126, Add: 127, Edit: 128, Delete: 129 },
  StudioBooking: { List: 103 },
  ContactUs: { List: 114 },
  Enquiry: { List: 118 },
  StudioRequested: { List: 122, Edit: 123 },
  StudioRejected: { List: 130, Edit: 131 },
  Booking: { List: 132, StatusChange: 133 },
  BookingRequest: { List: 134, StatusChange: 135 },
  BookingRejected: { List: 136, StatusChange: 137 },
  Vendor: { List: 138 },
  VendorRequest: { List: 139, StatusChange: 140 },
  VendorRejected: { List: 141 },
  walleteHistory: { List: 142 },
  BulkUpload: {
    Country: 107,
    State: 108,
    City: 109,
    Pincode: 110,
    CountryCode: 111,
    Category: 112,
    SubCategory: 113,
  },
};
